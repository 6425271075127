import {
    SET_USER_INFO,
    SET_USER_SESSION,
    SET_USER_BALANCE,
    SUB_BALANCE_UPDATE
} from "./actionTypes";

export function setUserSessionId(id) {
    return dispatch => {
        dispatch({type: SET_USER_SESSION, id});
    }
}


export function subBalanceUpdate() {
    return dispatch => {
        dispatch({
            eventOn: SUB_BALANCE_UPDATE,
            handle: (data) => {
                dispatch({type: SET_USER_BALANCE, balance: data.Balance})
            }
        })
    }
}
