import React from "react";
import Cards from "@components/Cards/CardList";
import classNames from "classnames";

function CardList({points, player, cards, winner = false}) {
    return (
        <div className={classNames("history-card-list", { active: winner})}>
            <div>
                <div className="point">{ points }</div>
                <div className="player">{ player }</div>
            </div>
            <div>
                <Cards cards={cards} className="playing-cards-item history-card-list-view"/>
            </div>
        </div>
    )
}

export default CardList