import BalanceIcon from "@resource/balanceIcon";
import BurgerMenuIcon from "@resource/BurgerMenuIcon";
import FullScreenInIcon from "@resource/fullScreenInIcon";
import FullScreenOutIcon from "@resource/fullScreenOutIcon";
import HistoryIcon from "@resource/historyIcon";
import HistoryLoadingIcon from "@resource/historyLoadingIcon";
import HistoryMobileIcon from "@resource/historyMobileIcon";
import HistoryTotalIcon from "@resource/historyTotalIcon";
import InfoIcon from "@resource/infoIcon";
import InfoMobileIcon from "@resource/infoMobileIcon";
import ModalCloseIcon from "@resource/modalCloseIcon";
import SwitchIcon from "@resource/switchIcon";
import TotalBetsIcon from "@resource/totalBetsIcon";
import WarningIcon from "@resource/warningIcon";
import HeaderCloseIcon from "@resource/headerCloseIcon";
import BackRoundHistoryIcon from "@resource/backRoundHistoryIcon";
import NoDataIcon from "@resource/noDataIcon";
import ActiveBetsIcon from "@resource/activeBetsIcon";
import PlusIcon from "@resource/plusIcon";
import MinusIcon from "@resource/minusIcon";
import MusicOffIcon from "@resource/musicOffIcon";
import MusicOnIcon from "@resource/musicOnIcon";
import SoundOnIcon from "@resource/soundOnIcon";
import SoundOffIcon from "@resource/soundOffIcon";
import LoadingIcon from "@resource/loadingIcon";
import DeleteBetsIcon from "@resource/deleteBetsIcon"

export {
    BalanceIcon,
    BurgerMenuIcon,
    FullScreenInIcon,
    FullScreenOutIcon,
    HistoryIcon,
    HistoryLoadingIcon,
    HistoryMobileIcon,
    HistoryTotalIcon,
    InfoIcon,
    InfoMobileIcon,
    ModalCloseIcon,
    SwitchIcon,
    TotalBetsIcon,
    WarningIcon,
    HeaderCloseIcon,
    BackRoundHistoryIcon,
    NoDataIcon,
    ActiveBetsIcon,
    PlusIcon,
    MinusIcon,
    MusicOffIcon,
    MusicOnIcon,
    SoundOnIcon,
    SoundOffIcon,
    LoadingIcon,
    DeleteBetsIcon
}