import {DefaultLanguageCode} from "@constants/Enums";

const store = {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [],
    history: {
        data: [],
        hasNext: false,
    },
    odds: {
        _7: "10.5:1",
        _7Up: "1:1",
        _7Down: "1:1",
        red: "0.9:1",
        black: "0.9:1",
        odd: "0.8:1",
        even: "1:1"
    },
    userBetList: [],
    cards: {},
    enabledBetAreas: false,
    customEnabledBetAreas: {
        "ABack": false,
        "ALay": false,
        "APair": false,
        "BBack": false,
        "BLay": false,
        "BPair": false
    },
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    winnerList: [], // getWinnerList(),
    gameHistory: {
        history: [],
        APercentage: 0,
        BPercentage: 0,
        DrawPercentage: 0,
    },
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    videoStreamConfig: {
        Live: null,
        StreamName: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    },
    selectedBoard: {
        board: -1,
        odd:0
    },
    inactivity: {
        count : 0,
        suspend: false
    },
    loadInfo:{
        "prediction-0":"",
        "prediction-1":"",
        "prediction-2":"",
        "prediction-3":"",
        "prediction-4":"",
        "prediction-5":"",
        "prediction-6":""
    }
}

export default store;

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}