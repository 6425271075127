import {combineReducers} from "redux";
import {
    availableLanguagesReducer as availableLanguages,
    selectedLanguagesReducer as selectedLanguages
} from "./languagesReducer";
import globalInfo from "./globalInfoRducer";
import settings from "./settingsReducer";
import winnerList from "./winnerListReducer";
import history from "./historyReducer";
import viewSetting from "./viewSettingsReducer";
import selectedChip from "./selectedChipReducer";
import totalBets from "./totalBetsReducer";
import {userReducer as user} from "./userReducer";
import roundId from "./roundIdReducer";
import timeTicker from "./timeTickerReducer";
import card from "./cardReducer";
import odds from "./oddsReducer";
import winner from "./winnerListReducer";
import historyLoading from "./historyLoadingReducer";
import enabledBetAreas from "./enabledBetAreasReducer";
import videoStreamConfig from "./videoStreamReducer";
import activeBets from "./activeBetsReducer";
import gameCondition from "./gameConditionReducer";
import userInfo from "./userInfoReducer";
import alertMessage from "./alertMessagesReducer";
import gameHistory from "./gameHistoryReducer";
import rules from "./rulesReducer";
import limit from "./limitReducers";
import userWon from "./userWonReducer";
import backgroundMusic from "./soundMusic/backgroundMusicReducer";
import sound from "./soundMusic/soundReducer";
import soundKey from "./soundMusic/soundKeyReudcer";
import volume from "./soundMusic/volumeReducer";
import selectedBoard from "./betReducers/selectedBoardReducer";
import inactivity from "./inactivityReducer";
import loadInfo from "./loadInfoReducer";


const rootReducer = combineReducers({
    globalInfo,
    availableLanguages,
    selectedLanguages,
    settings,
    winnerList,
    history,
    viewSetting,
    selectedChip,
    totalBets,
    user,
    roundId,
    timeTicker,
    card,
    odds,
    winner,
    historyLoading,
    enabledBetAreas,
    videoStreamConfig,
    activeBets,
    gameCondition,
    userInfo,
    alertMessage,
    gameHistory,
    rules,
    limit,
    userWon,
    selectedBoard,
    backgroundMusic,
    sound,
    soundKey,
    volume,
    inactivity,
    loadInfo
});

export default rootReducer;
