import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Ticker from "@components/roundInfo/ticker";
import {subscribeTimeTicker, resetTimeTicker} from "@actions/timeticker";
import {FormattedMessage} from "react-intl";
import { resetSelectBoard, createBet, subscribeBetResult, addBet, resetBets, setBordActive} from "@actions/betsAction";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";
import {PlusIcon, MinusIcon} from "@resource";
import Nominal from "@components/Nominal/Nominal";
import {Prediction} from "@constants/Enums"
import classNames from "classnames";
import {setSoundKey} from "@actions/soundMusicAction";

function RoundInfo({
                       subscribeTimeTicker,
                       timeTicker,
                       unSubscribeTickerWorker,
                       subscribeTickerWorker,
                       resetTimeTicker,
                       selectedBoard,
                       activeBets,
                       balance,
                       minStake,
                       maxStake,
                       resetSelectBoard,
                       createBet,
                       subscribeBetResult,
                       addBet,
                       resetBets,
                       setSoundKey,
                       setBordActive,
                   }) {
    const [betDuration, setBetDuration] = useState(0);
    const [betValue, setBetValue] = useState("0");
    const [disabledResetBet, setDisabledResetBet] = useState(true);
    const isSend = useRef(false);

    useEffect(() => {
        subscribeTimeTicker()
        subscribeBetResult()
    }, [])

    useEffect(() => {
        function getGameTimeDiff(e) {
            const timer = JSON.parse(e.data)
            setBetDuration(timer.data ? parseInt(timer.data / 1000) : 0);
        }

        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff)
        }
    }, []);

    useEffect(() => {
        if (betDuration <= 3 && betDuration !==0) {
            setBetValue("0")
            if (!isSend.current) {

                isSend.current = true;
                addBet()
            }
        }

        if (betDuration === 3) {
            resetSelectBoard()
            setBordActive(false)
        }
        
        if (betDuration === 0) {
            resetTimeTicker()
            isSend.current = false;
        }
    }, [betDuration]);

    useEffect(async () => {
        if (betDuration === 3) {
            await setSoundKey("noMoreBetsSound")
         }
         if (betDuration <= 3 && betDuration !==0) {
            setSoundKey("timerLastSecondsSound")
         }
    }, [betDuration])

    const changeBetValue = (value) => {
        const regExp = /^\d*[.]*(?:\.\d{1,2})*$/;
        if (value[0] === "0") {
            value = value.substring(1)
        }

        let activeBetsSum = 0;
        activeBets.forEach(item => {
            if (!item.accepted) {
                activeBetsSum += item.Stake
            }
        })
        if (regExp.test(value)) {
            if(+value <= balance - activeBetsSum && +value <= maxStake ) {
                if (!value){
                    value = 0
                }
                setBetValue(value)
            }
        }
    }
    const increment = () => {
        changeBetValue(+betValue === 0 ? 1 : +betValue + 1)
    }

    const decrement = () => {
        changeBetValue(+betValue -1)
    }

    useEffect(() => {
        let notAccepted = activeBets.filter(bet => !bet.accepted)
        setDisabledResetBet(!notAccepted.length);
    }, [activeBets])

    const addBetFromNominal = (price) => {
        if (selectedBoard.board !== -1) {
            setSoundKey("addBetSound")
            createBet(price, selectedBoard.board, selectedBoard.odd)
        }
    }

    return (
        <div className="round-info">    
            <div className="round-info-timer">
                <Ticker diff={timeTicker.diff} betDuration={betDuration} setSoundKey={setSoundKey}/>
                <span className="message">
                  {
                      betDuration > 3 ?  <FormattedMessage id="place_your_bets_please"/> :<FormattedMessage id="wait_for_placing_bets"/>
                  }
              </span>
            </div>
            <div className="round-info-bet-area">
                <div className="info-box">
                    <div className="lable">
                        <FormattedMessage id="bet_for"/> :
                        <div className="selected-board">
                            {
                                selectedBoard.board !== -1 ? <FormattedMessage id={Prediction[selectedBoard.board]} /> : <><FormattedMessage id="select" /> <span><FormattedMessage id="player" /></span></>
                            }
                        </div>
                    </div>
                    <div className="lable">
                        <FormattedMessage id="odds"/> :
                        <div className="selected-odd">
                            {selectedBoard.odd || 0}
                        </div>
                    </div>
                    <div className="lable">
                        <FormattedMessage id="stake"/> :
                        <div className="stake-input">
                            <button className={classNames("action-plus", {disabled: selectedBoard.board === -1})} onClick={increment}><PlusIcon /></button>
                            <input value={betValue} onChange={({target}) => changeBetValue(target.value)} disabled={selectedBoard.board === -1}/>
                            <button className={classNames("action-minus", {disabled: selectedBoard.board === -1})} onClick={decrement}><MinusIcon /></button>
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <button className={classNames("btn_2 w-100", {disabled: disabledResetBet || betDuration <= 3})} onClick={resetBets}>
                        <FormattedMessage id="reset_bet"/>
                    </button>
                    <button className={classNames("btn_2 w-100 active", {disabled: !(betValue && betValue >= minStake && betValue <= maxStake && selectedBoard.board !== -1)  })}
                            onClick={() => {createBet(betValue, selectedBoard.board, selectedBoard.odd);setBetValue("0"); resetSelectBoard(); setSoundKey("addBetSound")}}
                    >
                        <FormattedMessage id="place_bet"/>
                    </button>
                </div>
            </div>
            <div className="round-info-nominal">
                <Nominal addValue={addBetFromNominal} disabled={selectedBoard.board === -1}/>
            </div>
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        timeTicker: state.timeTicker,
        selectedBoard: state.selectedBoard,
        balance: state.userInfo.Balance,
        activeBets: state.activeBets,
        minStake: state.limit.Min,
        maxStake: state.limit.Max,
    }
}

const mapDispatchToProps = {
    subscribeTimeTicker,
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    resetTimeTicker,
    resetSelectBoard,
    createBet,
    subscribeBetResult,
    addBet,
    resetBets,
    setSoundKey,
    setBordActive,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoundInfo)