import React from "react";
import CardItem from "./CardItem";

function CardList({cards}) {
    return (
        <ul className="cards-list">
        <CardItem card={cards} />
     </ul>
    )
}

export default CardList;