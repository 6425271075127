import {
    SET_GAME_CONDITION, SET_ODDS,
    SET_ROUND_START, SET_TIME_TICKER,
    SUB_ROUND_START,
    SET_CARD,
    SET_DISABLE_BETS_AREAS 
} from "@actions/actionTypes";
// import {getRealOdd} from "@services/oddService";
import { CardTypeMap, CardsSuit } from "@constants/Enums";

export function subscribeRoundIdAction() {
    return dispatch => {
        dispatch({eventOn: SUB_ROUND_START,
            handle: (data) => {
                if (data.Card) {
                    dispatch({type: SET_CARD, card: {type: CardTypeMap[data.Card.Type], suit: CardsSuit[data.Card.Suit]}});
                }
                
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Start || 0, End: data.End || 0}});
                dispatch({type: SET_ROUND_START, roundId : data.RoundId});
                dispatch({type: SET_GAME_CONDITION, condition : true});
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                // dispatch({type: SET_USER_BALANCE, balance: data.UserInfo?.Balance || 0});
            }
        });
    }
}