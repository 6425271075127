import { SET_CARD, SUB_CARD, SET_SOUND_KEY } from "@actions/actionTypes";
import { CardsSuit, CardTypeMap } from "@constants/Enums";

export function subscribeCards() {
    return dispatch => {

        dispatch({
            eventOn: SUB_CARD,
            handle: (card) => {
                if (card) {
                    dispatch({type: SET_SOUND_KEY, payload: CardTypeMap[card.Type] + CardsSuit[card.Suit]})
                }
                dispatch({
                    type: SET_CARD,
                    card: {
                        suit: CardsSuit[card.Suit],
                        type: CardTypeMap[card.Type]
                    }
                });
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: SET_CARD, card: {}})
    }
}