import React, {useState, useEffect} from "react";
import cardBg from "@assets/img/cardBg.svg";
import openedCardBg from "@assets/img/opendedCardBg.svg";
import classNames from "classnames";

function CardItem({card}) {
    const [cardItem, setCardItem] = useState({
        suit: 0,
        type: 0
    })

    useEffect(() => {
        let timer = setTimeout(() => {
            setCardItem(card);
        }, 500)
        return () => {
            clearTimeout(timer);
        }
    }, [card])
    return (
        <li className="cards-list-item card">
        <div className={classNames("flip-card", {active: cardItem.type})}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={cardBg} alt={cardBg} />
                </div>
                <div className="flip-card-back">
                    <img src={openedCardBg} alt={openedCardBg} />
                    <i className={`cardIcon icon-${cardItem.type}${cardItem.suit}`}/>
                </div>
            </div>
        </div>
    </li>
    )
}

export default CardItem