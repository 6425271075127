import React, { useEffect } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { subscribeBetResult, selectBoard } from "@actions/betsAction";
import { connect } from "react-redux";
import { Prediction } from "@constants/Enums";
import { subscribeOdds } from "@actions/oddsAction";
import BoardMiddleArea from "./BoardElements/BoardMiddleArea";
import BoardMiddleArea1280 from "./BoardElements/BoardMiddleArea1280";
import BoardMiddleArea768 from "./BoardElements/BoardMiddleArea768";
import BoardMiddleAreaMobile from "./BoardElements/BoardMiddleAreaMobile";
import OddElement from "./BoardElements/OddElement";
import OddElement1280 from "./BoardElements/OddElement1280";
import OddElement768 from "./BoardElements/OddElement768";
import OddElementMobile from "./BoardElements/OddElementMobile";

function Board({
    threeD,
    subscribeOdds,
    odds,
    subscribeBetResult,
    enabledBetAreas,
    selectBoard,
    selectedBoard,
    loadInfo
}) {
    useEffect(() => {
        subscribeOdds();
        subscribeBetResult();
    }, []);

    return (
        <div
            className={classNames("bet-area", {
                threeD,
                "enabled-bet-areas": enabledBetAreas,
            })}
        >
            <div className="board-body">
                <div className="board-body-left">
                    <div className="board-body-item board-body-left-item board-body-left-item-top">
                        <OddElement
                            onClick={() =>
                                selectBoard(Prediction.Red, odds.red)
                            }
                            title="_red"
                            className={classNames({
                                active: selectedBoard === Prediction.Red,
                            })}
                            oddName={odds.red}
                        />
                        <OddElement1280
                            onClick={() =>
                                selectBoard(Prediction.Red, odds.red)
                            }
                            title="_red"
                            className={classNames({
                                active: selectedBoard === Prediction.Red,
                            })}
                            oddName={odds.red}
                        />
                        <OddElement768
                            onClick={() =>
                                selectBoard(Prediction.Red, odds.red)
                            }
                            title="_red"
                            className={classNames({
                                active: selectedBoard === Prediction.Red,
                            })}
                            oddName={odds.red}
                        />
                        <OddElementMobile
                            onClick={() =>
                                selectBoard(Prediction.Red, odds.red)
                            }
                            title="_red"
                            className={classNames({
                                active: selectedBoard === Prediction.Red,
                            })}
                            oddName={odds.red}
                        />
                    </div>
                    <div className={`${loadInfo['prediction-3'] > 0 ? 'board-body-left-top-book green' : ''}`}>{loadInfo['prediction-3'] > 0 ? loadInfo['prediction-3'] : ''}</div>
                    <div className="board-body-item board-body-left-item board-body-left-item-bottom">
                        <OddElement
                            onClick={() =>
                                selectBoard(Prediction.Black, odds.black)
                            }
                            title="_black"
                            className={classNames({
                                active: selectedBoard === Prediction.Black,
                            })}
                            oddName={odds.black}
                        />
                        <OddElement1280
                            onClick={() =>
                                selectBoard(Prediction.Black, odds.black)
                            }
                            title="_black"
                            className={classNames({
                                active: selectedBoard === Prediction.Black,
                            })}
                            oddName={odds.black}
                        />
                        <OddElement768
                            onClick={() =>
                                selectBoard(Prediction.Black, odds.black)
                            }
                            title="_black"
                            className={classNames({
                                active: selectedBoard === Prediction.Black,
                            })}
                            oddName={odds.black}
                        />
                        <OddElementMobile
                            onClick={() =>
                                selectBoard(Prediction.Black, odds.black)
                            }
                            title="_black"
                            className={classNames({
                                active: selectedBoard === Prediction.Black,
                            })}
                            oddName={odds.black}
                        />
                        <span className="board-body-left-item-side-bets">
                            <FormattedMessage id="side_bets" />
                        </span>
                    </div>
                    <div className={`${loadInfo['prediction-4'] > 0 ? 'board-body-left-bottom-book green' : ''}`}>{loadInfo['prediction-4'] > 0 ? loadInfo['prediction-4'] : ''}</div>
                </div>
                <div className="board-body-center">
                    <BoardMiddleArea selectBoard={selectBoard} />
                    <BoardMiddleArea1280 selectBoard={selectBoard} />
                    <BoardMiddleArea768 selectBoard={selectBoard} />
                    <BoardMiddleAreaMobile selectBoard={selectBoard} />
                    <div className="board-body-center-bottom-book">
                        <div className={`${loadInfo['prediction-2'] > 0 ? 'book-seven-down green' : ''}`}>{loadInfo['prediction-2'] > 0 ? loadInfo['prediction-2'] : ''}</div>
                        <div className={`${loadInfo['prediction-1'] > 0 ? 'book-seven green' : ''}`}>{loadInfo['prediction-1'] > 0 ? loadInfo['prediction-1'] : ''}</div>
                        <div className={`${loadInfo['prediction-0'] > 0 ? 'book-seven-up green' : ''}`}>{loadInfo['prediction-0'] > 0 ? loadInfo['prediction-0'] : ''}</div>
                    </div>
                </div>
                <div className="board-body-right">
                    <div className="board-body-item board-body-right-item board-body-right-item-top">
                        <OddElement
                            onClick={() =>
                                selectBoard(Prediction.Odd, odds.odd)
                            }
                            title="_odd"
                            className={classNames({
                                active: selectedBoard === Prediction.Odd,
                            })}
                            oddName={odds.odd}
                        />
                        <OddElement1280
                            onClick={() =>
                                selectBoard(Prediction.Odd, odds.odd)
                            }
                            title="_odd"
                            className={classNames({
                                active: selectedBoard === Prediction.Odd,
                            })}
                            oddName={odds.odd}
                        />
                        <OddElement768
                            onClick={() =>
                                selectBoard(Prediction.Odd, odds.odd)
                            }
                            title="_odd"
                            className={classNames({
                                active: selectedBoard === Prediction.Odd,
                            })}
                            oddName={odds.odd}
                        />
                        <OddElementMobile
                            onClick={() =>
                                selectBoard(Prediction.Odd, odds.odd)
                            }
                            title="_odd"
                            className={classNames({
                                active: selectedBoard === Prediction.Odd,
                            })}
                            oddName={odds.odd}
                        />
                    </div>
                    <div className={`${loadInfo['prediction-5'] > 0 ? 'board-body-right-top-book green' : ''}`}>{loadInfo['prediction-5'] > 0 ? loadInfo['prediction-5'] : ''}</div>
                    <div className="board-body-item board-body-right-item board-body-right-item-bottom">
                        <OddElement
                            onClick={() =>
                                selectBoard(Prediction.Even, odds.even)
                            }
                            title="_even"
                            className={classNames({
                                active: selectedBoard === Prediction.Even,
                            })}
                            oddName={odds.even}
                        />
                        <OddElement1280
                            onClick={() =>
                                selectBoard(Prediction.Even, odds.even)
                            }
                            title="_even"
                            className={classNames({
                                active: selectedBoard === Prediction.Even,
                            })}
                            oddName={odds.even}
                        />
                        <OddElement768
                            onClick={() =>
                                selectBoard(Prediction.Even, odds.even)
                            }
                            title="_even"
                            className={classNames({
                                active: selectedBoard === Prediction.Even,
                            })}
                            oddName={odds.even}
                        />
                        <OddElementMobile
                            onClick={() =>
                                selectBoard(Prediction.Even, odds.even)
                            }
                            title="_even"
                            className={classNames({
                                active: selectedBoard === Prediction.Even,
                            })}
                            oddName={odds.even}
                        />
                        <span className="board-body-right-item-side-bets">
                            <FormattedMessage id="side_bets" />
                        </span>
                    </div>
                    <div className={`${loadInfo['prediction-6'] > 0 ? 'board-body-right-bottom-book green' : ''}`}>{loadInfo['prediction-6'] > 0 ? loadInfo['prediction-6'] : ''}</div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        odds: state.odds,
        enabledBetAreas: state.enabledBetAreas,
        selectedBoard: state.selectedBoard.board,
        loadInfo: state.loadInfo
    };
}

const mapDispatchToProps = {
    subscribeOdds,
    subscribeBetResult,
    selectBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
