import React from "react";
import { FormattedMessage } from "react-intl";

export default function OddElement768({title, oddName, onClick, className, ...anyProps}) {

    return (
        <svg {...anyProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 68" className={`odd-element odd-element-768 ${className}`}>
            <g  onClick={onClick} id="Rectangle_1132" data-name="Rectangle 1132" fill="rgba(83,61,113,0.5)" stroke="#7a6893" strokeWidth="1">
                <rect width="100" height="68" rx="16" stroke="none" />
                <rect x="0.5" y="0.5" width="99" height="67" rx="15.5" fill="none" />
            </g>
            <text x="50"
                    y="11"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fff"
                    fontSize="15"
                    fontFamily="SF Compact Text Heavy, SF Compact Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    style={{textTransform: "uppercase"}}
                    onClick={onClick}>
                <tspan>
                    <FormattedMessage id={title} />
                </tspan>
            </text>
            <text x="50"
                    y="36"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fc0"
                    fontSize="15"
                    fontFamily="SF Compact Text Heavy, SF Pro Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    onClick={onClick}>
                <tspan>{oddName}</tspan>
            </text>
        </svg>
    )
}