import {
    SET_CARD,
    SUB_GAME_FINISH,
    RESET_USER_BET_LIST,
    SET_GAME_CONDITION,
    SET_ROUND_START,
    SET_LOAD_INFO,
    SET_ODDS,
    SUB_CANCEL_GAME,
    SET_TIME_TICKER,
    SET_ALERT_MSG,
    REQUEST_REFRESH_SESSION,
    REQUEST_GAME_START,
    CONNECT_TO_SOCKET,
    SUB_GAME_INIT,
    SET_GAME_INITIAL_DATA,
    SET_ALL_BETS,
    SET_USER_INFO,
    SET_VIDEO_STREAM_CONFIG,
    SET_DISABLE_BETS_AREAS,
    SET_LIMIT,
    SET_USER_WON,
    SET_WINNER_LIST
} from "@actions/actionTypes";
import {CardsSuit, CardTypeMap} from "@constants/Enums";
import initialStore from "../initialStore";
import {getLocalStorage, removeLocalStorage, setLocalStorage} from "@services/storageService";


export function subscribeGameFinish() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_GAME_FINISH,
            handle: (data) => {
                const {userInfo, roundId} = getState();
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`)
                dispatch({type: SET_CARD, card: {}});
                dispatch({type: RESET_USER_BET_LIST});
                dispatch({type: SET_GAME_CONDITION, condition : false});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_ODDS, odds: initialStore.odds});
                dispatch({type: SET_ALL_BETS, bets: []});
                dispatch({type: SET_USER_WON, wonAmount: {value: data.WonAmount}});
                dispatch({type: SET_WINNER_LIST, winners: data.Results});
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false})
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
            }
        })
    }
}


export function subscribeCanceledGame() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_CANCEL_GAME,
            handle: (data) => {
                const {userInfo} = getState();
                dispatch({type: SET_CARD, card: {}});
                dispatch({type: SET_ROUND_START, roundId : ""});
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
                dispatch({type: SET_ODDS, odds: initialStore.odds});
                dispatch({type: SET_TIME_TICKER, ticker: initialStore.timeTicker});
                dispatch({type: SET_ALERT_MSG, data: {type: 'info', messages: "game_is_canceled_your_bets_will_refund"}});
                dispatch({type: SET_ALL_BETS, bets: []});
                removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${data.roundId}`)
                removeLocalStorage(`acceptedBets_${userInfo.Id}_${data.roundId}`)
                dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: false})
                dispatch({type: SET_LOAD_INFO, load: initialStore.loadInfo});
            }
        })
    }
}

export function refreshSession(session) {
    return dispatch => {
        dispatch({
            type: REQUEST_REFRESH_SESSION,
            session
        })
    }
}

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(session) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            session
        })
    }
}

export function subGameInitData() {
    return dispatch => {
        dispatch({
            eventOn: SUB_GAME_INIT,
            handle: (data) => {
                let unAcceptedBets = [], bets = []
                var userBookData = [];
                if (data.Round) {
                    dispatch({type: SET_ALL_BETS, bets: data.Round.Bets});
                }
                if (data.Round?.Card) {
                    const card = data.Round.Card;
                    dispatch({
                        type: SET_CARD,
                        card: {
                            suit: CardsSuit[card.Suit],
                            type: CardTypeMap[card.Type]
                        }
                    })
                }
                dispatch({type: SET_TIME_TICKER, ticker: {Start: data.Round?.Start || 0, End: data.Round?.End || 0}});
                dispatch({type: SET_GAME_INITIAL_DATA, ...data});
                dispatch({type: SET_USER_INFO, user: data.Player});
                dispatch({type: SET_LOAD_INFO, load: data.Load?.Load || initialStore.loadInfo});
                dispatch({type: SET_VIDEO_STREAM_CONFIG, config: data.Stream});
                if(data.Round?.Start && data.Round?.End && data?.Round.Time < data.Round.End) {
                    dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: true})
                }
                dispatch({type: SET_LIMIT, limit: data.Limit})
                if (data.Player.Id && data.RoundStart?.RoundId) {
                    unAcceptedBets = getLocalStorage(`unAcceptedBets_${data.Player.Id}_${ data.RoundStart.RoundId}`) || []
                }
                if (data.PlayerBets?.Bets.length) {
                    data.PlayerBets.Bets.forEach(bet => {
                        bets.push({
                            accepted: true,
                            Prediction: bet.Prediction,
                            Stake: bet.Stake,
                            Odd: bet.Odd})
                    })
                    bets.forEach(item => {
                        userBookData['prediction-'+item.Prediction] = item.Stake;
                    })
                    dispatch({type: SET_LOAD_INFO, load: userBookData || initialStore.loadInfo});
                }
                if (data.RoundStart?.RoundId && data.Player?.Id) {
                    setLocalStorage(`acceptedBets_${data.Player.Id}_${data.RoundStart.RoundId}`, bets)
                }
                dispatch({type: SET_ALL_BETS, bets: [...unAcceptedBets, ...bets], startTime:  data.Timer?.Start});
            }
        })
    }
}