import React, {useEffect, useState} from "react";
import ToolTip from "@components/toolTip/toolTip";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";
import RoundInfoItem from "@components/history/RoundHistory/RoundInfoItem";
import CardList from "@components/history/RoundHistory/CardList";
import {FormattedMessage} from "react-intl";
import {CardsSuit, CardTypeMap, Prediction} from "@constants/Enums";
import {ModalCloseIcon} from "@resource";

function GameHistoryLastResult({round, ...restProps}) {
    const [activeRound, setActiveRound] = useState(false)
    const [Card, setCard] = useState({suit: 0,
        type: 0})

    useEffect(() => {
        if (round.Card) {
            setCard({
                suit: CardsSuit[round.Card.Suit],
                type: CardTypeMap[round.Card.Type]})
        }
    },[round])

    const trigger = (isActive) => {
        setActiveRound(isActive)
    }


    const getContent = () => {
        return (
            <div className="game-history-last-results-item">
                <div className="game-history-last-results-item-header">
                    <div className="title">
                        <FormattedMessage id="round_id" />: {round.RoundId}
                    </div>
                    <button className="circled-button small">
                        <ModalCloseIcon id={`GameHistoryLastResult${round.RoundId}`}/>
                    </button>
                </div>
                <div className="game-history-last-results-item-round-info">
                    <RoundInfoItem time={round.StartTime}
                                   timeTitle="game_start"/>
                    <RoundInfoItem time={round.EndTime}
                                   timeTitle="game_end"/>
                </div>
                <div className="game-history-last-results-item-round-cards">
                    <CardList player={<FormattedMessage id={Prediction[round.Main]} />}
                              cards={Card}
                              />
                </div>
            </div>
        )
    }
    return(
        <ToolTip title={getContent()} position={"top"} type="popup" triggerCallBack={trigger}{...restProps}>
            <GameHistoryIcon won={round.Main} type="large" active={activeRound}/>
        </ToolTip>
    )
}

export default GameHistoryLastResult