import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import {subscribeCards} from "@actions/cardsAction";
import CardList from "./CardList";

function Cards({card, subscribeCards, className}) {
    useEffect(() => {
        subscribeCards();
    }, [])

    return(
        <div className={`cards ${className||''}`}>
            <div className="cards-element">
               <CardList cards={card} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        card: state.card,
    }
}

const mapDispatchToProps = {
    subscribeCards
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards)