import React from "react";
import {FormattedMessage} from "react-intl";
import { Prediction, CardsSuit, CardTypeMap, BetsType } from "@constants/Enums";
import RoundInfoItem from "./RoundInfoItem";
import CardList from "./CardList";
import {floatNumberWithSpaces} from "@services/numberFormatServices";

export function RoundHistory({round}) {
    
    return (
        <div className="history-round">
            <div className="history-round-info">
                <RoundInfoItem time={round.startTime}
                                timeTitle="game_start"
                                balance={round.balanceBefore}
                                balanceTitle="balance_before_the_round" />
                <RoundInfoItem time={round.endTime}
                                timeTitle="game_end"
                                balance={round.balanceAfter}
                                balanceTitle="balance_after_the_round" />
            </div>
            <div className="round-cards">
                <CardList cards={{type: CardTypeMap[round.card.type], suit: CardsSuit[round.card.suit]}}/>
            </div>
            <div className="round-bets">
                <div className="tbl">
                    <div className="row">
                        <div className="cell"><FormattedMessage id="bet_type" /></div>
                        <div className="cell"><FormattedMessage id="bet" /></div>
                        <div className="cell"><FormattedMessage id="odd" /></div>
                        <div className="cell"><FormattedMessage id="win_lose" /></div>
                    </div>
                    {
                        round.bets.map(item => (
                            <div className="row" key={item.main}>
                                <div className="cell"><FormattedMessage id={Prediction[item.main]} /></div>
                                <div className="cell">{floatNumberWithSpaces(item.stake)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.odd)}</div>
                                <div className="cell">{floatNumberWithSpaces(item.status === BetsType.win ? item.win : item.status === BetsType.refund ? 0 : -item.stake || 0, 0)}</div>
                            </div>
                        ))
                    }
                    <div className="row">
                        <div className="cell"><FormattedMessage id="total" />:</div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => b.stake + a, 0))}</div>
                        <div className="cell"></div>
                        <div className="cell">{floatNumberWithSpaces(round.bets.reduce((a, b) => (b.status === BetsType.win ? b.win : b.status === BetsType.refund ? 0 : b.status === BetsType.lose ? -b.stake : 0) + a, 0), 0)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoundHistory;