import React, {useEffect, useState} from "react";
import {gameHistory} from "@constants/Enums";
import {HistoryTotalIcon} from "@resource";
import GameHistoryIcon from "@components/gameHistory/gameHistoryIcon";

function GameHistoryHeader({history}) {
    const [total, setTotal] = useState(0)
    const [downWin, setDownWin] = useState(0)
    const [_7Win, set_7Win] = useState(0)
    const [upWind, setupWind] = useState(0)
    useEffect(() => {
        let down = 0,
            up = 0,
            _7 = 0;
        history.forEach(item => {
            if (item.Main === gameHistory.D) {
                down++
            }

            if (item.Main === gameHistory._7) {
                _7++
            }

            if (item.Main === gameHistory.U) {
                up++
            }
        })
        setTotal(history.length);
        setDownWin(down);
        setupWind(up);
        set_7Win(_7);
    }, [history])
    return (
        <div className="game-history-header">
            <ul className="list">
                <li className="list-item">
                   <div className="icon"><HistoryTotalIcon /></div> {total}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.D} type="small"/> {downWin}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory["_7"]} type="small _7"/> {_7Win}
                </li>
                <li className="list-item">
                    <GameHistoryIcon won={gameHistory.U} type="small"/> {upWind}
                </li>
            </ul>
        </div>
    )
}

export default GameHistoryHeader