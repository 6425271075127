import React from "react";
import { FormattedMessage } from "react-intl";

export default function OddElement({title, oddName, onClick, className, ...anyProps}) {

    return (
        <svg {...anyProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 84" className={`odd-element ${className}`}>
            <g onClick={onClick} id="Rectangle_1132" data-name="Rectangle 1132" fill="rgba(83,61,113,0.5)" stroke="#7a6893" strokeWidth="1">
                <rect width="122" height="84" rx="20" stroke="none" />
                <rect x="0.5" y="0.5" width="121" height="83" rx="19.5" fill="none"/>
            </g>
            <text x="61"
                    y="16"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fff"
                    fontSize="17"
                    fontFamily="SF Compact Text Heavy, SF Compact Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    style={{textTransform: "uppercase"}}
                    onClick={onClick}>
                <tspan>
                    <FormattedMessage id={title} />
                </tspan>
            </text>
            <text x="61"
                    y="53"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fc0"
                    fontSize="17"
                    fontFamily="SF Compact Text Heavy, SF Pro Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    onClick={onClick}>
                <tspan>{oddName}</tspan>
            </text>
        </svg>
    )
}