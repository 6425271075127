import React from "react";
import { FormattedMessage } from "react-intl";

export default function OddElementMobile({title, oddName, onClick, className, ...anyProps}) {

    return (
        <svg {...anyProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 48" className={`odd-element odd-element-mobile ${className}`}>
           <g onClick={onClick} id="Rectangle_27" data-name="Rectangle 27" fill="rgba(83,61,113,0.5)" stroke="#7a6893" strokeWidth="1">
                <rect width="80" height="48" rx="12" stroke="none" />
                <rect x="0.5" y="0.5" width="79" height="47" rx="11.5" fill="none" />
            </g>
            <text x="40"
                    y="4"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fff"
                    fontSize="12"
                    fontFamily="SF Compact Text Heavy, SF Compact Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    style={{textTransform: "uppercase"}}
                    onClick={onClick}>
                <tspan>
                    <FormattedMessage id={title} />
                </tspan>
            </text>
            <text x="40"
                    y="22"
                    textAnchor="middle"
                    transform="translate(0 16)"
                    fill="#fc0"
                    fontSize="12"
                    fontFamily="SFProText-Heavy, SF Pro Text"
                    fontWeight="800"
                    letterSpacing="0.04em"
                    onClick={onClick}>
                <tspan>{oddName}</tspan>
            </text>
        </svg>
    )
}